import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./OffplanDetailGallery.scss"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const OffplanDetailGallery = ({ imageData, offplanData, openGallery }) => {
  const [isOpen, setIsOpen] = useState(openGallery ? true : false)
  const [photoIndex, setPhotoIndex] = useState(0)

  var propertyLightImages =
    imageData?.strapi_json_value?.length > 0 ?
    imageData?.strapi_json_value.map(img =>
      img.srcUrl ? img.srcUrl : img?.url
    )
    :imageData?.length>0?imageData.map(img =>
      img.srcUrl ? img.srcUrl : img?.url
    ):""

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const galleryImageData=imageData?.strapi_json_value?.length>0?imageData?.strapi_json_value:imageData?.length>0?imageData:""

 
  const imagename = "new-developments.images.slider"
  const {isMobile} = useDeviceMedia()

  if(!galleryImageData?.length>0) return
  return (
    <div className="offplan-gallery-wrapper" id="off_plan_gallery">
      <Container>
        <div className="image-section_offplan">
          <CustomSlider
            // offplan
            arrowOnMobile
            noDots
            showOneOnTab
            showArrows
            iconchange
            className="offplan_propertis-slider"
            slidecount={isMobile?1:2}
          >
            {galleryImageData?.map((image, index) => {
               let processedImages = JSON.stringify({})
               if (offplanData?.imagetransforms) {
                 processedImages = offplanData?.imagetransforms?.images_Transforms
               }
              return (
                <div
                  className="image-list"
                  // onClick={e => openPropertyImage(e, 0)}
                >
                  <GGFXImage
                    ImageSrc={image}
                    altText={`${
                      image?.alternativeText
                        ? image?.alternativeText
                        : "Offplan slider Image"
                    }`}
                    imagetransforms={offplanData?.ggfx_results}
                    renderer="srcSet"
                    imagename={imagename}
                    strapiID={offplanData?.strapi_id}
                    className="offplan-img"
                  />
                  <button className="gallery_btn" onClick={e => openPropertyImage(e, 0)}>
                    <span>
                      <i className="icon icon-sqftnew"/>
                    </span>
                    <span className="gallery-text">Open Gallery</span>
                  </button>
                </div>
              )
            })}
          </CustomSlider>
        </div>
      </Container>

      {isOpen && propertyLightImages?.length > 0 && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
    </div>
  )
}

export default OffplanDetailGallery
