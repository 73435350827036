import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./AmmenitiesComponent.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const AmmenitiesComponent = ({ ammentiesData,isPreviewEnabled }) => {
  const { isLargeScreen } = useDeviceMedia()
  return (
    <>
    {ammentiesData&&
    <div className="offplan-details-ammenities">
      <Container>
        <div className="offplan-icon-module">
          <ScrollAnimation
            className="content-section_offplan"
            animateIn="animate__slideInUp"
            animateOnce
            delay={100}
            offset={100}
          >
            <h2 className="heading">{ammentiesData?.title}</h2>
            {(ammentiesData?.content?.data?.content||(isPreviewEnabled&&ammentiesData?.content))&&
            <p className="description">
              <ContentModule Content={ammentiesData?.content?.data?.content||ammentiesData?.content} />
            </p>
            }

          </ScrollAnimation>
          {ammentiesData?.icon_list?.length > 0 && (
            <div className="icon-section_offplan">
              {ammentiesData?.icon_list?.map((item, index) => {
                return (
                  <>
                    <ScrollAnimation
                      className="item_list-offplan"
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={index * 100}
                      offset={100}
                    >
                      {(item?.image?.url||item?.image?.data?.attributes?.url)&&
                        <img src={item?.image?.url||item?.image?.data?.attributes?.url} alt={item?.heading}/>
                      }
                      <p>{item.heading}</p>
                    </ScrollAnimation>
                  </>
                )
              })}
            </div>
          )}
        </div>
      </Container>
    </div>
    }
    </>
  )
}

export default AmmenitiesComponent